import { createAction, props } from '@ngrx/store';
import { EstateImageObject, EstateObject } from '../../models/estate-object.model';
import { MaklerProfile } from '../../components/object-makler/model/object-makler.model';

export const getCurrentEstate = createAction('[Current Estate] Get current Current Estate');

export const getCurrentEstateSuccess = createAction(
  '[Current Estate] Get current Current Estate Success',
  props<{ currentEstate: EstateObject }>()
);

export const getCurrentEstateFailure = createAction(
  '[Current Estate] Get current Current Estate Failure',
  props<{ error: string }>()
);

export const getCurrentEstateMaklerProfile = createAction('[Current Estate] Get current Current Estate Makler Profile');

export const getCurrentEstateMaklerProfileSuccess = createAction(
  '[Current Estate] Get current Current Estate Makler Profile Success',
  props<{ maklerProfile: MaklerProfile }>()
);

export const getCurrentEstateMaklerProfileFailure = createAction(
  '[Current Estate] Get current Current Estate Makler Profile Failure',
  props<{ error: string }>()
);

export const getCurrentEstateMaklerPhoto = createAction(
  '[Current Estate] Get current Current Estate Makler Photo',
  props<{ userNr: string }>()
);

export const getCurrentEstateMaklerPhotoSuccess = createAction(
  '[Current Estate] Get current Current Estate Makler Photo Success',
  props<{ maklerPhoto: string }>()
);

export const getCurrentEstateMaklerPhotoFailure = createAction(
  '[Current Estate] Get current Current Estate Makler Photo Failure',
  props<{ error: string }>()
);

export const getCurrentEstateRundgangLink = createAction(
  '[Current Estate] Get current Current Estate Link ',
  props<{ estateId: string }>()
);

export const getCurrentEstateRundgangLinkSuccess = createAction(
  '[Current Estate] Get current Current Estate Link Success',
  props<{ currentEstateRundgangLink: string }>()
);

export const getCurrentEstateRundgangLinkFailure = createAction(
  '[Current Estate] Get current Current Estate Link Failure',
  props<{ error: string }>()
);

export const getCurrentEstateImages = createAction(
  '[Current Estate] Get Current Estate Images',
  props<{ estateId?: string }>()
);

export const getCurrentEstateImagesSuccess = createAction(
  '[Current Estate] Get Current Estate Images Success',
  props<{ currentEstateImages: EstateImageObject[] }>()
);
export const getCurrentEstateImagesFailure = createAction(
  '[Current Estate] Get Current Estate Images Failure',
  props<{ error: string }>()
);

export const getCurrentEstateThumbs = createAction(
  '[Current Estate] Get Current Estate Thumbs',
  props<{ estateId: string }>()
);

export const getCurrentEstateThumbsSuccess = createAction(
  '[Current Estate] Get Current Estate Thumbs Success',
  props<{ currentEstateThumbs: EstateImageObject[] }>()
);
export const getCurrentEstateThumbsFailure = createAction(
  '[Current Estate] Get Current Estate Thumbs Failure',
  props<{ error: string }>()
);
