import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentEstateState } from './current-estate.state';

export const getCurrentEstateState = createFeatureSelector<CurrentEstateState>('currentEstate');

export const selectCurrentEstate = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstate
);

export const selectCurrentEstateImages = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstateImages
);

export const selectCurrentEstateThumbs = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstateThumbs
);
export const selectCurrentEstateMakler = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstate?.benutzer
);
export const selectCurrentEstateMaklerProfile = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstateMaklerProfile
);

export const selectCurrentEstateMaklerPhoto = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstateMaklerPhoto
);

export const selectCurrentEstateRundgangLink = createSelector(
  getCurrentEstateState,
  (currentEstateState: CurrentEstateState) => currentEstateState?.currentEstateRundgangLink
);
